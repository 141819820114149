import React from 'react';
import './index.css';
import duck from '../images/duck.jpg';
import { NavLink } from 'react-router-dom';

function IndexComponents() {
    return (
        <div className="container">
            <img className="duck-img" src={duck} alt="" />
            <div className="links">
                <NavLink to="/mist" className="mist-link">Mist Miracles</NavLink>
                <NavLink to="/ocean" className="nature-link">Ocean Outcomes</NavLink>
                <NavLink to="/flower" className="flower-link">Flowered Flora</NavLink>
                <NavLink to="/bird-animal" className="bird-animal-link">Finest Fauna</NavLink>
                <NavLink to="/other" className="other-link">Et ceteras</NavLink>
            </div>
            <div className="footer">
                <label className="authority">© Designed, developed and maintained by</label>
                <a href="http://sathviksoftech.in" className="sathvik-link">Sathvik Softech</a>
                <label className="copyright"> The photographs in this website are subjected to Copyright act 2020</label>
            </div>
        </div>
    )
}

export default IndexComponents
